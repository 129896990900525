import { computed } from 'vue';
import { useQuery, useResult } from '@vue/apollo-composable';

import { useNotification, useLoading } from '@/modules/core';
import { useTenancy } from '@/modules/auth';

import { SUPPLIERS_QUERY, SUPPLIERS_NEW_QUERY } from '../utils/suppliers-queries';

export const useSuppliers = () => {
  const { error } = useNotification();
  const { currentTenantId } = useTenancy();
  const {
    result,
    loading: queryLoading,
    refetch,
    onError,
  } = useQuery(
    SUPPLIERS_QUERY,
    () => ({ businessId: currentTenantId.value }),
    () => ({ fetchPolicy: 'cache-first' })
  );
  const suppliers = useResult(result, []);
  const loading = useLoading(queryLoading);

  onError((err) => {
    console.error('useSuppliers', err);
    error();
  });

  return { suppliers, loading, refetch };
};

export const useSuppliersNew = () => {
  const { error } = useNotification();
  const { currentTenantId } = useTenancy();
  const {
    result,
    loading: queryLoading,
    refetch,
    onError,
  } = useQuery(
    SUPPLIERS_NEW_QUERY,
    () => ({ businessId: currentTenantId.value }),
    () => ({ fetchPolicy: 'cache-first' })
  );
  const suppliers = computed(() => (result.value ? result.value.suppliersNew?.nodes ?? [] : []));
  const loading = useLoading(queryLoading);

  onError((err) => {
    console.error('useSuppliers', err);
    error();
  });

  return { suppliers, loading, refetch };
};
