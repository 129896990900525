import { computed, ref } from 'vue';
import { gql } from '@apollo/client/core';
import { useQuery } from '@vue/apollo-composable';

import { useNotification, useLoading } from '@/modules/core';

export const useBankAccounts = (businessId) => {
  const { error } = useNotification();

  const {
    result,
    loading: queryLoading,
    refetch,
    onError,
  } = useQuery(BANK_ACCOUNTS_QUERY, { businessId }, () => ({
    enabled: !!businessId.value,
  }));

  const bankAccounts = computed(() => result.value?.bankAccounts?.nodes ?? []);
  const variables = ref({ businessId });
  const loading = useLoading(queryLoading, variables);

  onError((err) => {
    console.error('suppliers - useBankAccounts', err);
    error();
  });

  return {
    bankAccounts,
    loading,
    refetch,
  };
};

export const BANK_ACCOUNTS_QUERY = gql`
  query bankAccounts($businessId: ID!) {
    bankAccounts(businessId: $businessId) {
      nodes {
        id
        businessId
        accountName
        bankNumber
        branchNumber
        accountNumber
        routingNumber
        filePath
        filePathUrl
        manualValidation
      }
    }
  }
`;
