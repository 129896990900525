import { useQuery, useResult } from '@vue/apollo-composable';
import { SUPPLIER_QUERY } from '@/modules/suppliers/utils/suppliers-queries';

import { useNotification } from '@/modules/core';

export function useSupplier(supplierId) {
  const { error } = useNotification();
  const { result, loading, onError } = useQuery(
    SUPPLIER_QUERY,
    () => ({ supplierId: supplierId.value }),
    () => ({ enabled: !!(supplierId && supplierId?.value) })
  );
  const supplier = useResult(result, {});

  onError((err) => {
    console.error('useSupplier', err);
    error();
  });

  return { supplier, loading };
}
