import { gql } from '@apollo/client/core';

export const BUSINESS_PATCH_MUTATION = gql`
  mutation businessPatchMutation($businessId: ID!, $businessPatchParams: BusinessPatchInput!) {
    businessPatch(businessId: $businessId, patchParams: $businessPatchParams) {
      id
      name
    }
  }
`;

export const BUSINESS_CREATE_MUTATION = gql`
  mutation businessCreateMutation($businessCreateParams: BusinessCreateInput!) {
    businessCreate(createParams: $businessCreateParams) {
      id
      name
    }
  }
`;
