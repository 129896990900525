import SimpleSchema from 'simpl-schema';
import moment from 'moment';

const required = (pricingMethod) =>
  function () {
    const shouldBeRequired = this.field('pricingMethod').value === pricingMethod;
    if (shouldBeRequired) {
      // inserts
      if (!this.operator) {
        if (!this.isSet || this.value === null || this.value === '') return 'required';

        // updates
      } else if (this.isSet) {
        if (this.operator === '$set' && (this.value === null || this.value === '')) return 'required';
        if (this.operator === '$unset') return 'required';
        if (this.operator === '$rename') return 'required';
      }
    }
  };

export const TERM_TYPES = ['pricing', 'discount', 'promotion'];
const baseTermSchema = new SimpleSchema({
  type: { type: String, allowedValues: TERM_TYPES },
  supplierId: SimpleSchema.RegEx.Id,
  itemId: String,
  fromDate: Date,
  toDate: {
    type: Date,
    optional: true,
    custom() {
      if (this.isSet && moment.utc(this.value).isBefore(this.field('fromDate').value)) return 'overlappingDates';
    },
  },
});

export const PRICING_METHODS = ['fixedPrice', 'priceList', 'priceIndex', 'perOrder'];
export const PRICING_METHODS_ENUM = {
  FIXED_PRICE: 'fixedPrice',
  PRICE_LIST: 'priceList',
  PRICE_INDEX: 'priceIndex',
  PER_ORDER: 'perOrder',
};
export const AGREEMENT_TYPES = {
  VERBAL: 'verbal',
  SIGNED: 'signed',
  EMAIL: 'email',
  TEXT_MESSAGE: 'textMessage',
};

export const pricingTermSchema = new SimpleSchema({
  pricingMethod: { type: String, allowedValues: PRICING_METHODS },
  price: { type: SimpleSchema.Integer, optional: true, custom: required('fixedPrice') },
  priceListId: { type: String, optional: true, custom: required('priceList') },
  priceIncludingTax: { type: Boolean, defaultValue: false },
  priceIndexId: { type: String, regEx: SimpleSchema.RegEx.Id, optional: true, custom: required('priceIndex') },
  priceIndexItemId: { type: String, optional: true },
  priceIndexOffset: { type: SimpleSchema.Integer, optional: true },
  priceIndexComission: { type: SimpleSchema.Integer, optional: true },
});
pricingTermSchema.extend(baseTermSchema);

const REWARD_TYPES = ['discount', 'quantity', 'dollarAmount'];
const REWARD_REALIZATION_TIMES = ['onBill', 'quarterly', 'yearly'];
const CONDITION_TYPES = ['quantityAmount', 'dollarAmount'];
const CONDITION_LIMITS = ['bill', 'ongoing'];
export const promotionTermSchema = new SimpleSchema({
  rewardType: { type: String, allowedValues: REWARD_TYPES },
  rewardValue: Number,
  rewardRealizationTime: { type: String, allowedValues: REWARD_REALIZATION_TIMES },
  conditionType: { type: String, allowedValues: CONDITION_TYPES, optional: true },
  conditionValue: { type: Number, optional: true },
  conditionLimit: { type: String, allowedValues: CONDITION_LIMITS, optional: true },
});
promotionTermSchema.extend(baseTermSchema);
