import { gql } from '@apollo/client/core';
import { useMutation } from '@vue/apollo-composable';

export const useBankAccountDelete = () => {
  const { mutate, onError, onDone } = useMutation(BANK_ACCOUNT_DELETE_MUTATION);

  onError((err) => {
    console.error('suppliers - useBankAccountDelete', err);
  });

  return {
    deleteBankAccount: mutate,
    onError,
    onDone,
  };
};

export const BANK_ACCOUNT_DELETE_MUTATION = gql`
  mutation bankAccountDelete($id: ID!) {
    bankAccountDelete(id: $id)
  }
`;
