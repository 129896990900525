import SimpleSchema from 'simpl-schema';
import { Tracker } from 'meteor/tracker';
import { unitsOfMeasure } from '@/modules/products/models/product';
import { Items } from '@/imports/api/collections';

export const PENDING_REASONS = ['notPurchasedRecently', 'uomUknown', 'goodUknown'];
export const ITEM_TYPES = ['good', 'other', 'pending'];

// custom validation methods
function isValueSet() {
  return this.isSet && this.value !== null && this.value !== '';
}

export const ItemSchema = new SimpleSchema(
  {
    supplierId: SimpleSchema.RegEx.Id,
    name: {
      type: String,
      custom() {
        const uniqueQuery = { name: this.value, supplierId: this.field('supplierId').value };
        if (this.isUpdate) uniqueQuery._id = { $ne: this.docId };
        if (Items.find(uniqueQuery).count()) return 'notUnique';
      },
    },
    code: {
      type: String,
      optional: true,
      custom() {
        const uniqueQuery = { code: this.value, supplierId: this.field('supplierId').value };
        if (this.isUpdate) uniqueQuery._id = { $ne: this.docId };
        if (this.field('codeUniqueness').value) uniqueQuery.codeUniqueness = this.field('codeUniqueness').value;
        if (this.isSet && this.value && Items.find(uniqueQuery).count()) return 'notUnique';
      },
    },
    codeUniqueness: { type: String, optional: true },
    manufacturerCode: { type: String, optional: true },
    type: { type: String, allowedValues: ITEM_TYPES, optional: true },
    pendingReason: { type: String, allowedValues: PENDING_REASONS, optional: true },
    goodId: {
      type: String,
      regEx: SimpleSchema.RegEx.Id,
      optional: true,
      custom() {
        if (this.field('type').value === 'good' && !isValueSet.call(this)) return 'required';
      },
    },
    purchaseUOM: {
      type: Number,
      optional: true,
      custom() {
        if (this.field('type').value === 'good' && !isValueSet.call(this)) return 'required';
      },
    },
    purchaseMeasurement: {
      type: String,
      allowedValues: Object.keys(unitsOfMeasure),
      optional: true,
      custom() {
        if (this.field('type').value === 'good' && !isValueSet.call(this)) return 'required';
      },
    },
    purchaseUnit: {
      type: String,
      allowedValues: Object.values(unitsOfMeasure).reduce((total, uoms) => total.concat(uoms), []),
      optional: true,
      custom() {
        if (this.field('type').value === 'good' && !isValueSet.call(this)) return 'required';
      },
    },
  },
  { tracker: Tracker }
);
