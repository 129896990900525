import { gql } from '@apollo/client/core';

export const SUPPLIER_QUERY = gql`
  query supplier($supplierId: ID!) {
    supplier(id: $supplierId) {
      id
      name
      logoUrl
      number
    }
  }
`;

export const SUPPLIERS_QUERY = gql`
  query suppliers($businessId: ID!) {
    suppliers(businessId: $businessId) {
      logoUrl
      name
      id
      number
    }
  }
`;

export const SUPPLIERS_NEW_QUERY = gql`
  query suppliersNew($businessId: ID!) {
    suppliersNew(businessId: $businessId) {
      nodes {
        logoUrl
        name
        id
        number
      }
    }
  }
`;
