<template>
  <div>
    <el-dialog
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      width="68rem"
      custom-class="rounded"
      @close="onClose"
    >
      <h2 slot="title">
        <template v-if="supplierId">{{ $t('supplierAccountDialog.title') }}</template>
      </h2>
      <Tabs class="mb-5 mx-4" :tabs="tabs" :active-tab.sync="activeTab" />
      <el-form v-if="dialogVisible" ref="form" :model="model" :rules="rules" class="px-4">
        <template v-if="activeTab === 0">
          <div>
            <h3>{{ $t('supplierAccountDialog.tabs.paymentMethod.tabName') }}</h3>
            <template v-if="bankAccounts.length">
              <div
                v-for="bankAccount in bankAccounts"
                :key="bankAccount.id"
                class="d-flex g-0 mt-4 justify-content-between"
              >
                <div class="col-8 d-flex align-items-center">
                  <p>{{ getBankAccountDetails(bankAccount) }}</p>
                  <Tag v-if="bankAccount.manualValidation" class="me-2">{{
                    $t('supplierAccountDialog.tabs.paymentMethod.manuallyVerified')
                  }}</Tag>
                </div>
                <div>
                  <Button type="link" @click="previewReference = bankAccount">{{
                    $t('supplierAccountDialog.tabs.paymentMethod.reference')
                  }}</Button>
                  <Button class="remove-button" type="link" @click="handleDeleteClick(bankAccount)">{{
                    $t('supplierAccountDialog.tabs.paymentMethod.remove')
                  }}</Button>
                </div>
              </div>
            </template>
            <div class="d-flex justify-content-between my-4">
              <p class="text-typography-secondary">
                {{ bankAccounts.length ? '' : $t('supplierAccountDialog.tabs.paymentMethod.noBankAccount') }}
              </p>
              <Button type="text" @click="isAddPaymentMethodModalOpen = true">
                {{ `+ ${$t('supplierAccountDialog.tabs.paymentMethod.addPaymentMethod')}` }}
              </Button>
            </div>
          </div>
        </template>
      </el-form>
      <span slot="footer">
        <Button type="secondary" @click="onClose">{{ $t('commons.cancel') }}</Button>
        <Button @click="submitForm" :disabled="!hasPaymentManageBankAccounts">
          <template v-if="supplierId">{{ $t('commons.update') }}</template>
          <template v-else>{{ $t('commons.add') }}</template>
        </Button>
      </span>
    </el-dialog>
    <AddBankAccountModal
      v-if="isAddPaymentMethodModalOpen"
      :supplier-name="model.name"
      :business-id="supplierId"
      :business-company-id="supplierCompanyId"
      :country-code="countryCode"
      :supported-banks="supportedBanks"
      @close="isAddPaymentMethodModalOpen = false"
    />
    <el-dialog
      v-if="previewReference"
      top="2%"
      custom-class="rounded"
      visible
      :show-close="false"
      @close="previewReference = null"
    >
      <template #title>
        <div class="d-flex justify-content-between px-2">
          <h3>{{ $t('supplierAccountDialog.tabs.paymentMethod.reference') }}</h3>
          <Button type="text" class="p-0 text-typography-primary action-btn" @click="previewReference = null">
            <CloseIcon />
          </Button>
        </div>
      </template>
      <div class="px-6" style="height: 80vh">
        <embed width="100%" height="100%" name="plugin" :src="previewReference.filePathUrl" type="application/pdf" />
      </div>
      <template #footer><div style="height: 8px"></div></template>
    </el-dialog>
  </div>
</template>

<script type="text/javascript">
import { ref, computed, getCurrentInstance } from 'vue';
import { pick, filter, identity } from 'ramda';
import { useMutation } from '@vue/apollo-composable';

import { CloseIcon } from '@/assets/icons';
import { Tabs, Button, Tag } from '@/modules/core';
import { constants } from '@/modules/purchase-management';
import { useTenancy } from '@/modules/auth';
import AddBankAccountModal from '@/modules/payment/customer/components/AddBankAccountModal.vue';
import { useSupportedBanks } from '@/modules/payment';
import { useGlobalPermissions } from '@/modules/permissions';
import { useBusinessById } from '@/modules/business/compositions/business';

import { useBankAccounts, useBankAccountDelete } from '../compositions';
import { BUSINESS_PATCH_MUTATION, BUSINESS_CREATE_MUTATION } from '../compositions/business';

const { BUSINESS_TYPES } = constants;

const EMPTY_CONTACT_MODEL = {
  phone: '',
  email: '',
  address: '',
};

const EMPTY_MODEL = {
  name: '',
  number: '',
  legalName: '',
  contact: EMPTY_CONTACT_MODEL,
};

const getEmptyModel = () => JSON.parse(JSON.stringify(EMPTY_MODEL));

export default {
  components: { Tabs, Button, AddBankAccountModal, Tag, CloseIcon },
  props: {
    visible: { type: Boolean, default: false },
    supplierId: { type: String, default: '' },
    supplierCompanyId: { type: String, default: '', required: true },
    defaults: { type: Object, default: null },
    startingTab: { type: Number, default: 0 },
  },
  setup(props, { emit }) {
    const root = getCurrentInstance().proxy;
    const { currentTenant } = useTenancy();

    const activeTab = ref(props.startingTab);
    const showConflict = ref(false);
    const businessId = computed(() => props.supplierId);
    const { bankAccounts } = useBankAccounts(businessId);
    const { business } = useBusinessById(computed(() => ({ businessId: businessId.value })));

    const countryCode = computed(() => business.value?.countryCode);

    const { supportedBanks } = useSupportedBanks(countryCode);
    const { hasPaymentManageBankAccounts } = useGlobalPermissions();
    const { mutate: patchBusiness, onDone: patchOnDone, onError: patchOnError } = useMutation(BUSINESS_PATCH_MUTATION);
    const {
      mutate: createBusiness,
      onDone: createOnDone,
      onError: createOnError,
    } = useMutation(BUSINESS_CREATE_MUTATION);

    const { deleteBankAccount } = useBankAccountDelete();

    patchOnError((err) => {
      if (err.graphQLErrors?.[0].extensions.response?.status === 409) {
        showConflict.value = true;
        root.$message.error(root.$t('supplierAccountDialog.conflict'));
      } else {
        root.$message.error(root.$t('supplierAccountDialog.updateError'));
      }
    });
    patchOnDone(() => {
      emit('close');
      root.$message.success(root.$t('supplierAccountDialog.updateSuccess'));
    });

    createOnError((err) => {
      if (err.graphQLErrors?.[0].extensions.response?.status === 409) {
        showConflict.value = true;
        root.$message.error(root.$t('supplierAccountDialog.conflict'));
      } else {
        root.$message.error(root.$t('supplierAccountDialog.createError'));
      }
    });
    createOnDone(() => {
      emit('close');
      root.$message.success(root.$t('supplierAccountDialog.createSuccess'));
    });

    return {
      hasPaymentManageBankAccounts,
      countryCode,
      supportedBanks,
      patchBusiness,
      createBusiness,
      activeTab,
      bankAccounts,
      deleteBankAccount,
      currentTenant,
      showConflict,
      previewReference: ref(null),
    };
  },
  data() {
    return {
      isAddPaymentMethodModalOpen: false,
      model: getEmptyModel(),
      rules: {
        name: { required: true, message: 'Please enter name' },
        legalName: { required: true, message: 'Please enter legal name ' },
        number: { required: true, message: 'Please enter company ID' },
      },
    };
  },
  computed: {
    tabs() {
      const relevantTabs = [];
      if (this.supplierId) {
        relevantTabs.push({
          text: this.$t('supplierAccountDialog.tabs.paymentMethod.tabName'),
        });
      }
      return relevantTabs;
    },
    dialogVisible: {
      get() {
        return this.visible;
      },
      set(value) {
        this.$emit('update:visible', value);
      },
    },
  },
  watch: {
    defaults: {
      handler(newVal) {
        this.model = { ...getEmptyModel(), ...newVal };
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    getBankAccountDetails(bankAccount) {
      const { accountName, accountNumber, bankNumber, branchNumber, routingNumber } = bankAccount;
      const bankName = this.supportedBanks[bankNumber];
      if (this.countryCode === 'IL') {
        return `${accountName}, ${bankName} (${bankNumber}), ${this.$t(
          'supplierAccountDialog.tabs.paymentMethod.bankBranchNumber'
        )} ${branchNumber}, ${this.$t('supplierAccountDialog.tabs.paymentMethod.bankAccountNumber')} ${accountNumber}`;
      } else if (this.countryCode === 'US') {
        return `${accountName}, ${routingNumber}, ${this.$t(
          'supplierAccountDialog.tabs.paymentMethod.bankAccountNumber'
        )} ${accountNumber}`;
      }
    },
    onClose() {
      this.$emit('close');
    },
    submitForm() {
      this.showConflict = false;
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          if (this.supplierId) {
            const businessPatchParams = pick(Object.keys(EMPTY_MODEL), {
              ...this.model,
              contact: filter(identity, this.model.contact),
            });
            await this.patchBusiness({ businessId: this.supplierId, businessPatchParams });
          } else {
            if (this.currentTenant.type === BUSINESS_TYPES.serviceProvider) {
              const businessCreateParams = {
                ...this.model,
                parentId: this.currentTenant.id,
                type: BUSINESS_TYPES.supplier,
                contact: filter(identity, this.model.contact),
              };
              this.createBusiness({ businessCreateParams });
            }
          }
        }
      });
    },
    handleDeleteClick(bankAccount) {
      this.$confirm(
        this.$t('supplierAccountDialog.tabs.paymentMethod.deleteConfirmationText'),
        this.$t('supplierAccountDialog.tabs.paymentMethod.deleteConfirmationTitle'),
        {
          confirmButtonText: this.$t('supplierAccountDialog.tabs.paymentMethod.confirmDelete'),
          confirmButtonClass: 'el-button--danger',
          cancelButtonText: this.$t('cancel'),
          cancelButtonClass: 'el-button--secondary',
        }
      )
        .then(async () => {
          await this.deleteBankAccount({ id: bankAccount.id }, { refetchQueries: ['bankAccounts'] });
        })
        .catch(() => null);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/stylesheets/scss/global';
::v-deep .el-dialog__body {
  color: $typography-primary;
  padding: 0;
}

.remove-button {
  color: #e52044;
  &:hover {
    text-decoration-color: #e52044;
  }
}

.action-btn {
  height: fit-content;
  &:hover {
    background: $secondary;
  }
}
</style>
