import apiConnection from '@/imports/api/api_connection';

export const getSuppliersSummaryData = (data) => apiConnection.callPromise('suppliers.getSummaryData', data);
export const lastPurchased = (data) => apiConnection.callPromise('suppliers.lastPurchased', data);
export const insertItem = (data) => apiConnection.callPromise('suppliers.insertItem', data);
export const updateItem = (data) => apiConnection.callPromise('suppliers.updateItem', data);

export const insertItemGroup = (data) => apiConnection.callPromise('itemGroups.insert', data);
export const removeItemGroup = (data) => apiConnection.callPromise('itemGroups.remove', data);
export const updateItemGroup = (data) => apiConnection.callPromise('itemGroups.update', data);

export const configureTerms = (data) => apiConnection.callPromise('suppliers.configureTerms', data);
